import React from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'

import { Section } from 'components/Section'
import { Flex } from 'components/Grid'
import { NewsItem } from './NewsItem'

const NewsTemplate = ({ data }) => {
  const { edges: posts } = data.allMarkdownRemark

  return (
    <Section title="Новини" my={[5, 5, 6]} py={[5, 5, 6]} bg="section.grey">
      <Flex
        width={1}
        flexDirection={['column', 'column', 'row']}
        justifyContent="space-between"
        alignItems={['center', 'center', 'flex-start']}
        mx={-3}
      >
        {posts &&
          posts.map(({ node: post }) => (
            <NewsItem
              flex="1 1 auto"
              px={[0, 3]}
              mt={4}
              key={post.id}
              post={post}
            />
          ))}
      </Flex>
    </Section>
  )
}

NewsTemplate.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }).isRequired,
}

export const News = () => (
  <StaticQuery
    query={graphql`
      query NewsRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "news-item" } } }
          limit: 3
        ) {
          edges {
            node {
              excerpt(pruneLength: 200)
              id
              fields {
                slug
              }
              frontmatter {
                title
                image {
                  childImageSharp {
                    fluid(maxWidth: 380, maxHeight: 250, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                      presentationWidth
                      presentationHeight
                    }
                  }
                }
                templateKey
                tags
                date(formatString: "MMMM DD, YYYY")
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <NewsTemplate data={data} count={count} />}
  />
)

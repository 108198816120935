import React from 'react'
import PropTypes from 'prop-types'
import styled from 'util/style'

import { Flex, Box } from 'components/Grid'
import { Text } from 'components/Typography'

export const Wrapper = styled(Flex)`
  max-width: 1150px;
`

const SectionTemplate = ({ title, children, ...props }) => {
  return (
    <Box as="section" my={[5, 5, 6]} color="textColor" {...props}>
      <Wrapper
        mx="auto"
        px={[3, 3, 0]}
        flexDirection="column"
        alignItems="center"
      >
        <Text fontSize={[32, 36, 40]} textAlign="center" as="h1">
          {title}
        </Text>
        {children}
      </Wrapper>
    </Box>
  )
}

SectionTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export const Section = props => {
  return <SectionTemplate {...props} />
}

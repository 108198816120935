import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import SEO from 'components/SEO'
import Layout from 'components/Layout'
import { Section } from 'components/Section'
import { NewsItem } from 'components/News'
import { Flex } from 'components/Grid'

export const NewsIndexPageTemplate = ({ title, data }) => (
  <Section title={title}>
    <Flex flexWrap="wrap" justifyContent="space-between">
      {data &&
        data.map(post => (
          <NewsItem px={[0, 3]} key={post.id} post={post} mt={4} mb={[4, 5]} />
        ))}
    </Flex>
  </Section>
)

NewsIndexPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
}

const NewsIndexPage = ({ data }) => {
  const { edges } = data.allMarkdownRemark
  const { footerData = {}, headerData = {} } = data

  const news = edges.map(edge => edge.node)
  const title = 'Новини'

  let hData = {}
  if (headerData.edges && headerData.edges.length > 0) {
    hData = headerData.edges[0].node.frontmatter
  }

  let fData = {}
  if (footerData.edges && footerData.edges.length > 0) {
    fData = footerData.edges[0].node.frontmatter
  }

  return (
    <Layout headerData={hData} footerData={fData}>
      <SEO title={title} />
      <NewsIndexPageTemplate title={title} data={news} />
    </Layout>
  )
}

NewsIndexPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default NewsIndexPage

export const pageQuery = graphql`
  query NewsIndexPageTemplate {
    ...LayoutFragment
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "news-item" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 200)
          id
          fields {
            slug
          }
          frontmatter {
            title
            image {
              childImageSharp {
                fluid(maxWidth: 380, maxHeight: 250, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                  presentationWidth
                  presentationHeight
                }
              }
            }
            templateKey
            tags
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`

import React from 'react'
import PropTypes from 'prop-types'
import shortid from 'shortid'

import styled, { themeGet, themePx } from 'util/style'
import { Flex, Box } from 'components/Grid'

const DateWrapper = styled(Flex)`
  font-size: 12px;
  font-weight: 600;
  color: ${themeGet('colors.lightGrey')};
  align-items: center;
`
const StyledDate = styled(Box)`
  text-transform: uppercase;
`
const Separator = styled(Box)`
  height: 16px;
  width: 1px;
  background-color: ${themeGet('colors.separator.grey')};
`
export const Tags = styled(Box)`
  display: flex;
  margin: 0;
  list-style: none;
`
export const Tag = styled(Box)`
  margin: 0;
  padding-left: ${themePx('space.2')};
  text-transform: uppercase;
  color: ${themeGet('colors.blue')};

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
`

export const Date = ({ date, tags }) => (
  <DateWrapper>
    <StyledDate>{date}</StyledDate>
    {tags && tags.length > 0 && (
      <>
        <Separator mx={2} />
        <Tags as="ul">
          {tags.map(tag => (
            <Tag key={shortid.generate()} as="li">
              {tag}
            </Tag>
          ))}
        </Tags>
      </>
    )}
  </DateWrapper>
)
Date.propTypes = {
  date: PropTypes.string.isRequired,
  tags: PropTypes.array,
}

Date.defaultProps = {
  tags: [],
}

import React from 'react'
import PropTypes from 'prop-types'

import { Box } from 'components/Grid'
import { Text } from 'components/Typography'
import NonStretchedImage from 'components/NonStretchedImage'
import { ActionLink } from 'components/ActionLink'
import { Date } from './Date'

import { NewsBox } from './styles'

export const NewsItem = ({ post, featured, ...props }) => (
  <NewsBox
    flexDirection={['column', 'column', featured ? 'row' : 'column']}
    width={[1, featured ? 1 : 1 / 3]}
    {...props}
  >
    <Box mr={[0, featured ? 3 : 0]} width={[1, 1, 1]}>
      <NonStretchedImage
        fluid={post.frontmatter.image.childImageSharp.fluid}
        alt="Image"
      />
    </Box>
    <Box ml={[0, featured ? 3 : 0]} width={[1, 1, 1]}>
      <Box>
        <Text
          mt={[2, featured ? 0 : 2]}
          mb={2}
          fontSize={featured ? 3 : 1}
          fontWeight={700}
        >
          {post.frontmatter.title}
        </Text>
        <Date date={post.frontmatter.date} tags={post.frontmatter.tags} />
        <Box my={2}>{post.excerpt}</Box>
      </Box>
      <ActionLink
        label="Прочети"
        linkURL={post.fields.slug}
        color={featured ? 'colors.primary' : 'colors.textColor'}
        hoverColor={featured ? 'colors.orange' : 'colors.primary'}
      />
    </Box>
  </NewsBox>
)

NewsItem.propTypes = {
  post: PropTypes.object.isRequired,
  featured: PropTypes.bool,
}

NewsItem.defaultProps = {
  featured: false,
}

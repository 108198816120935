import React from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'

import { Section } from 'components/Section'
import { Flex } from 'components/Grid'
import { NewsItem } from './NewsItem'

const FeaturedTemplate = ({ data }) => {
  const { edges: posts } = data.allMarkdownRemark
  if (posts.length === 0) {
    return null
  }

  const featuredPost = posts[0].node

  return (
    <Section title="Актуално" my={[5, 5, 6]} py={[5, 5, 6]} bg="section.grey">
      <Flex
        width={1}
        flexDirection={['column', 'column', 'row']}
        justifyContent="space-between"
        alignItems="center"
      >
        <NewsItem mt={4} px={[0, 3, 0]} post={featuredPost} featured />
      </Flex>
    </Section>
  )
}

FeaturedTemplate.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }).isRequired,
}

export const Featured = () => (
  <StaticQuery
    query={graphql`
      query FeaturedQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: {
            frontmatter: {
              templateKey: { eq: "news-item" }
              tags: { in: "актуално" }
            }
          }
          limit: 1
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                image {
                  childImageSharp {
                    fluid(maxWidth: 550, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                      presentationWidth
                      presentationHeight
                    }
                  }
                }
                templateKey
                tags
                date(formatString: "MMM DD, YYYY")
              }
            }
          }
        }
      }
    `}
    render={data => <FeaturedTemplate data={data} />}
  />
)

import styled, { themeGet, themePx } from 'util/style'
import { Flex, Box } from 'components/Grid'

export const StyledContainer = styled(Box)`
  ${props =>
    props.color && `background-color: ${themeGet(props.color)(props)};`};
  color: ${themeGet('colors.textColor')};
`

export const Wrapper = styled(Flex)`
  max-width: 1150px;
`
export const NewsBox = styled(Flex)`
  color: ${themeGet('colors.textColor')};
  ${({ width }) => width && `max-width: ${width}px;`}
`
export const ImageBox = styled(Flex)``
export const TextBox = styled(Flex)``

const getFontSize = featured => (featured ? 'fontSizes.3' : 'fontSizes.1')

export const Title = styled(Box)`
  font-size: ${props => themePx(getFontSize(props.featured))(props)};
  font-weight: 700;
  ${props => props.color && `color: ${themeGet(props.color)(props)};`};
`
export { Box }

import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

const NonStretchedImage = props => {
  let normalizedProps = props
  const { fluid, style } = props
  if (fluid && fluid.presentationHeight) {
    normalizedProps = {
      ...props,
      style: {
        ...(style || {}),
        maxWidth: fluid.presentationWidth,
        maxHeight: fluid.presentationHeight,
        margin: '0 auto', // Center the image
      },
    }
  }

  return <Img {...normalizedProps} />
}

NonStretchedImage.propTypes = {
  fluid: PropTypes.object.isRequired,
  style: PropTypes.object,
}

NonStretchedImage.defaultProps = {
  style: {},
}

export default NonStretchedImage

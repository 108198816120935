import React from 'react'
import PropTypes from 'prop-types'

import styled, { themeGet, themePx } from 'util/style'
import { Flex } from 'components/Grid'
import CustomLink from 'components/CustomLink'

import ArrowRight from '../images/arrow-right.inline.svg'

const Link = styled(Flex)`
  position: relative;

  & > a,
  & > a:visited {
    color: ${props => themeGet(props.color)(props)};
    font-weight: 600;
    text-decoration: none;
    transition: all 0.25s ease-in-out;
  }

  & g {
    transition: all 0.25s ease-in-out;
    fill: ${props => themeGet(props.color)(props)};
  }

  &:hover,
  &:focus {
    & > a {
      color: ${props => themeGet(props.hoverColor)(props)};
    }

    & g {
      fill: ${props => themeGet(props.hoverColor)(props)};
    }
  }

  & > a > svg {
    margin-left: ${themePx('space.2')};
    margin-bottom: -1px;
  }
`
export const ActionLink = ({ label, linkURL, color, hoverColor, ...props }) => (
  <Link alignItems="center" color={color} hoverColor={hoverColor}>
    <CustomLink linkURL={linkURL} {...props}>
      {label}
      <ArrowRight />
    </CustomLink>
  </Link>
)

ActionLink.propTypes = {
  label: PropTypes.string.isRequired,
  linkURL: PropTypes.string.isRequired,
  color: PropTypes.string,
  hoverColor: PropTypes.string,
}

ActionLink.defaultProps = {
  color: 'colors.textColor',
  hoverColor: 'colors.primary',
}
